<template>
  <div id="broadcast-report">
    <div class="row">
      <div class="col-12 mb-5">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-right">
                <b-form-group>
                  <b-button
                    variant="warning"
                    @click="onBack"
                  >
                    <span><i class="uil uil-arrow-left"></i> Back</span>
                  </b-button>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <h5 class="card-title mt-0 mb-0 header-title">Broadcast Report</h5>
              </div>
              <div class="col-md-9 d-flex justify-content-end">
                <input v-model="statsDatatable.queries.search" type="text" class="form-control form-control-sm" placeholder="Search..." style="width: 200px;">
                <select
                  v-model="statsDatatable.queries.status"
                  class="form-control ml-2 form-control-sm"
                  style="width: 200px;"
                >
                  <option value="">All</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Failed">Failed</option>
                </select>
                <button
                  class="ml-1 btn btn-primary btn-sm"
                  @click="getSentStats"
                >
                  <i class="uil uil-search-alt"></i>
                </button>
              </div>
            </div>
            <div v-if="!loadingStats && !loadingBroadcast" class="table-responsive mt-4">
              <b-table
                :items="stats.data"
                :fields="statsDatatable.columns"
                :sort-by.sync="statsDatatable.queries.sort_by"
                :sort-desc.sync="statsDatatable.queries.sort_desc"
                :sort-direction="statsDatatable.queries.sort_direction"
                :per-page="statsDatatable.queries.per_page"
                :no-local-sorting="true"
                @sort-changed="statsSortChanged"
              >
                <template v-slot:cell(processed_at)="data">
                  {{ data.item.processed_at | moment('YYYY-MM-DD HH:mm:ss') }}
                </template>

                <template v-slot:cell(messageStatus)="data">
                  <span
                    class="text-primary"
                    :class="{
                      'text-danger': data.item.messageStatus === 'Failed',
                      'text-success': data.item.messageStatus === 'Delivered',
                    }"
                    >{{ data.item.messageStatus }}</span
                  >
                </template>

                <template v-slot:cell(messageError)="data">
                  <span
                    v-if="providerType === 'email'"
                    >{{ data.item.email_response }}</span
                  >
                  <span
                    v-else
                    >{{ data.item.messageError }}</span
                  >
                </template>
                
                <template v-slot:cell(engagementStatus)="data">
                  <div class="d-flex engagement-wrapper">
                    <div style="width: 32px">
                      <span v-if="data.item.email_clicked" v-b-tooltip.hover :title="getEngagementTooltip(data.item, 'email_clicked')">
                        <i class="uil uil-mouse-alt text-primary"></i>
                      </span>
                    </div>
                    <div style="width: 32px">
                      <span v-if="data.item.email_opened" v-b-tooltip.hover :title="getEngagementTooltip(data.item, 'email_opened')">
                        <i class="uil uil-envelope-open text-primary"></i>
                      </span>
                    </div>
                    <div style="width: 32px">
                      <span v-if="data.item.email_unsubscribed" v-b-tooltip.hover :title="getEngagementTooltip(data.item, 'email_unsubscribed')">
                        <i class="uil uil-envelope text-danger"></i>
                      </span>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(optOut)="data">
                  <template v-if="providerType === 'sms'">
                    {{ data.item.opt_out | moment($store.getters['auth/dateFormat']) }}
                  </template>
                  <template v-else-if="providerType === 'email'">
                    {{ data.item.email_opt_out | moment($store.getters['auth/dateFormat']) }}
                  </template>
                </template>

                <template v-slot:cell(actions)="data">
                  <template v-if="providerType === 'sms'">
                    <span v-if="!!data.item.twilio_opt_out"
                      v-b-tooltip.hover
                      class="btn" 
                      title="We can't Opt In this contact as customer unsubscribed on his/her side."
                      :style="{ color: data.item.opt_out ? '#df2424' : '#6c757d', fontSize: '18px' }"
                    >
                      <i class="uil uil-ban"></i>
                    </span>
                    <span v-else
                      class="btn" 
                      :style="{ color: data.item.opt_out ? '#df2424' : '#6c757d', fontSize: '18px' }"
                      @click="onToggleOptOut(data.item)">
                      <b-spinner v-if="processing" small />
                      <i v-else class="uil uil-ban"></i>
                    </span>
                  </template>
                  <template v-else-if="providerType === 'email'">
                    <span
                      class="btn" 
                      :style="{ color: data.item.email_opt_out ? '#df2424' : '#6c757d', fontSize: '18px' }"
                      @click="onToggleOptOut(data.item)">
                      <b-spinner v-if="processing" small />
                      <i v-else class="uil uil-ban"></i>
                    </span>
                  </template>
                </template>
              </b-table>
              <div class="mt-4">
                <div class="float-left mt-2">
                  Total {{ stats.meta.total }} records
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="statsDatatable.queries.page"
                      :total-rows="stats.meta.total"
                      :per-page="statsDatatable.queries.per_page"
                      @change="onPageChanged"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showOptoutModal" title="Opt Contact Out">
      <p v-if="selectedRow"
        >Are you sure you want to opt contact
        <b>#{{ selectedRow.contact_id }}</b> out?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showOptoutModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="toggleOptOut">Opt-Out</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
  },

  data() {
    return {
      stats: { data: [], meta: { total: 0 }},
      statsDatatable: {
        columns: [
          { key: 'phone', label: 'Number' },
          { key: 'email', label: 'Email' },
          { key: 'messageStatus', label: 'Status' },
          { key: 'messageError', label: 'Error Message' },
          { key: 'processed_at', label: 'ProcessedAt', sortable: true },
          { key: 'optOut', sortable: true },
          { key: 'actions' }
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: '',
          search: '',
          sort_desc: true,
          sort_by: '',
        },
      },
      loadingStats: false,
      loadingBroadcast: false,
      broadcast: undefined,
      processing: false,
      selectedRow: undefined,
      showOptoutModal: false,
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    providerType() {
      return this.broadcast && this.broadcast.provider
    }
  },

  watch: {
  },

  mounted() {
    this.getBroadcast()
    this.getSentStats()
  },

  methods: {
    onBack() {
      this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider} })
    },
    statsSortChanged(args) {
      this.statsDatatable.queries.sort_by = args.sortBy
      this.statsDatatable.queries.sort_desc = args.sortDesc
      this.statsDatatable.queries.sort_direction = args.sortDirection
      this.statsDatatable.queries.page = 1
      this.getSentStats() 
    },

    onPageChanged(page) {
      this.statsDatatable.queries.page = page
      this.getSentStats()
    },

    getEngagementTooltip(item, type) {
      if (type === 'email_clicked' && item.email_clicked)
        return `This contact has clicked the email on ` + this.$options.filters.datetime(item.email_clicked, this.$store.getters['auth/dateFormat'])

      if (type === 'email_opened' && item.email_opened)
        return `This contact has opened the email on ` + this.$options.filters.datetime(item.email_opened, this.$store.getters['auth/dateFormat'])

      if (type === 'email_unsubscribed' && item.email_unsubscribed)
        return `This contact has unsubscribed the email on ` + this.$options.filters.datetime(item.email_unsubscribed, this.$store.getters['auth/dateFormat'])

      return undefined;
    },

    getBroadcast() {
      this.loadingBroadcast = true

      this.$store
        .dispatch('broadcast/find', this.$route.params.id)
        .then((data) => {
          this.broadcast = data
          if (this.statsDatatable && this.statsDatatable.columns)
          {
            if (this.providerType === 'email')
              this.statsDatatable.columns.splice(3, 0, { key: 'engagementStatus', label: 'Engagements' })
            else
              this.statsDatatable.columns.splice(3, 0, { key: 'messageErrorCodee', label: 'ErrorCode' },)
          }
          this.loadingBroadcast = false
        })
        .catch(() => {
          this.$router.push({ name: 'business.broadcasts.index' })
          this.loadingBroadcast = false
        })
    },

    getSentStats() {
      this.loadingStats = true
      const params = {
        broadcastId: this.$route.params.id,
        queries: this.statsDatatable.queries
      }
      this.$store
        .dispatch('broadcast/sentStats', params)
          .then((stats) => {
            this.stats = stats
            this.loadingStats = false
          })
          .catch(() => {
            this.loadingStats = false
          })
    },

    onToggleOptOut(row)
    {
      this.selectedRow = row
      if (this.providerType === 'email' && row.email_opt_out || this.providerType === 'sms' && row.opt_out)
        this.toggleOptOut()
      else
        this.showOptoutModal = true
    },

    toggleOptOut() {
      if (this.processing) return;

      this.processing = true
      let param = {
        id: this.selectedRow.contact_id,
        type: this.providerType
      }
      this.$store
        .dispatch('contact/toggleOptOut', param)
        .then((contact) => {
          this.processing = false
          this.selectedRow.opt_out = contact.opt_out
          this.selectedRow.email_opt_out = contact.email_opt_out
          this.showOptoutModal = false;
        })
        .catch(() => {
          this.processing = false
          this.showOptoutModal = false;
        })
    },
  },
}
</script>

<style lang="scss">
#broadcast-report {
  .flatpickr-input {
    background: #FFF !important;
  }
}
.engagement-wrapper {
  i {
    font-size: 18px;
    cursor: pointer;
  }
}
</style>